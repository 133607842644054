import React from "react"
import { navigate } from "gatsby"

class Page50 extends React.Component {
  componentDidMount() {
    navigate("/blog/type-brigade-50-cancelled/")
  }

  render() {
    return <div />
  }
}

export default Page50
